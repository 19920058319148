export default function () {
    const introArticle =
        '私たちが住むこの地球は、生命に満ちあふれた豊かな惑星です。未知の生物も含めると、地球上には500万～3000万種もの多種多様な生物が生きていると言われています。全生物が地球上でこれだけの素晴らしい営みを行い、生きる事ができるのは、生命があるからです。\n \
        \n \
        しかし私たち人間も含めて、どの生物も、自分で生命を創り自分でこの世に誕生したものはおりません。いくら科学が発達しても、生命という目に見えないエネルギーは、人為的に創造できるものではありません。自分で創れないにかかわらず生命があるということは、生命を与えて下さった、人智を超えた御存在がいて下さるということです。\n \
        \n \
        人間・動物・植物など生物の全ては、「大自然の大いなる生命」のお力・お働きにより、生命を与えて頂いてこの世に誕生し、「大自然の大いなる生命」の営みにより活かされ生きています。大自然の大いなる生命の営みが無ければ全生物の生存は一切ありません。大自然の大いなる生命の営みは、全生物にとって絶対に必要不可欠です。\n \
        \n \
        ところが私たちは今まで、大自然の大いなる生命の営みも、この大いなる営みの全てを行って下さっている「大自然の大いなる意志・御心」も、全く分からずに生きてきました。大自然の大いなる意志と御心により、大宇宙の壮大で膨大な仕組み、地球の自然環境、全生物の生存、あらゆる全てをしていただいています。しかし、あまりにも偉大な御心なので、私たち人間は分からず、自然のこと、当たり前のこととして、全く気にも留めておりませんでした。\n \
        \n \
        大自然界とは、大自然の大いなる意志・御心＝大自然の摂理・真理法則を学び、大自然の大いなる生命の営みに感謝し、大自然の摂理・真理法則に沿って、より価値のある素晴らしい人生を生きるための会です。大自然の摂理・真理法則に沿って素直に生きれば、人生はもっともっと素晴らしくなり、日々の実生活は、より楽しく充実します。たとえどんな運命や環境であろうとも、人生課題としてどんな事情や病気があろうとも、大自然の摂理・真理法則に沿って素直に日々プラスの実行をしてゆけば、誰であろうとも、今まで体験したことのない、幸せで価値ある充実した人生を生きることが出来るのです。\n \
        \n \
        大自然の摂理・真理法則を学ぶことは、地球人類の全員にとっても絶対必要不可欠であり、急務です。現在私たち地球人類には、万人に普遍共通の心の価値基準がありません。それぞれの人種・民族・宗教宗派がそれぞれの考えや判断で作り出した価値基準を互いに主張し合い、その違いにより対立し、最新技術を駆使して殺し合っています。科学技術や物質文明がいくら発達しようとも、それを使う人間の意識・心がマイナスでバラバラであれば、地球平和は永遠に訪れません。\n \
        \n \
        もしも地球に住む人類の全員が「大自然の大いなる意志・御心」が分かり、大自然の摂理・真理法則という普遍的な価値基準を持つことが出来れば、世界は一つになり、地球上から戦争や対立・争いは無くなります。逆に、お互いに支え合い、助け合い、人類全体・地球全体の為に全員がプラスをし合って生きる、素晴らしい世界になるでしょう。その為には、私たち一人一人が大自然の摂理・真理法則をしっかりと学ぶことが、今こそ必要なのです。\n \
        \n \
        さあ、ご一緒に、大自然の大いなる意志・御心を実感し感謝して、幸せで豊かな人生を生きてみましょう！'
    return { introArticle }
}
