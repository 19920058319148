<template>
    <div id="Intro">
        <section id="intro-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">はじめに</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="intro-main" class="b25">
            <h2 class="f29 w70 hide-mb">はじめに</h2>
            <article class="f14 w70 f12-mb w80-mb" id="intro-article">
                <p v-html="wrap(introArticle)"></p>
            </article>
            <footer
                class="hide"
                @click="toggle()"
                :class="{ expanded: expanded }"
            >
                <p v-show="!expanded" class="f14-mb">続きを読む</p>
                <i class="f12-mb icon-expand"></i>
                <p v-show="expanded" class="f14-mb">続きを読む</p>
            </footer>
        </section>
    </div>
</template>
<script>
import { ref } from 'vue'
import { addBackgroundImage, wrap } from '@/utils/common.js'
import getIntroData from '@/data/getIntroData'
export default {
    name: 'Intro',
    setup() {
        const { introArticle } = getIntroData()
        const expanded = ref(false)
        function toggle() {
            let el = document.getElementById('intro-article')
            expanded.value = !expanded.value
            if (expanded.value) {
                el.style.height = el.scrollHeight + 'px'
            } else {
                el.style.height = '32em'
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                })
            }
        }
        return { addBackgroundImage, wrap, introArticle, expanded, toggle }
    },
}
</script>
<style lang="scss" scoped>
#intro-main {
    h2 {
        position: relative;
        padding: 40px 0;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: -40px;
            top: -40px;
            width: 200px;
            height: 200px;
            border-radius: 100px;
            background-color: rgba($color: $majorRed, $alpha: 0.2);
        }
    }
    article {
        position: relative;
    }

    @media screen and (min-width: 768px) {
        padding: 100px 0;
    }
    @media screen and(max-width:767px) {
        padding: 50px 0 40px;
        article {
            height: 32em;
            overflow: hidden;
            transition: 1s ease-in-out;
        }
        footer {
            margin-top: 40px;
            text-align: center;
            i {
                color: $majorPurple;
            }

            &.expanded {
                i {
                    display: block;
                    transform: rotate(180deg);
                }
            }
        }
    }
}
</style>
